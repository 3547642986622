.custom-date-picker {
  z-index: 2;
}

.custom-date-picker input {
  color: rgb(102, 102, 102);
  cursor: pointer;
}

.custom-date-picker select {
  color: rgb(102, 102, 102);
}

.custom-date-picker > div {
  border: none;
}

.custom-date-picker > div > button {
  display: none;
}

.date-picker-wrapper {
  color: rgb(102, 102, 102);
  display: flex;
  font-size: small;
  bottom: 8px;
  position: absolute;
}

.date-picker-wrapper-month {
  position: absolute;
  right: 5px;
}

.task-editable-title {
  cursor: pointer;
  line-height: 1.5;
}

.task-editable-title:focus-visible {
  color: hsl(0, 0%, 51%);
  cursor: text;
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
